import {GET_CONSULTATIONS,CLOSE_CONSULTATIONS,ON_ERROR,ON_SUCCESS,ON_LOAD} from '../constants/actionTypes'

const initailState={
    isLoading:true,
    consultaitons:[],
    errMess:null,
    successMess:null
}

export const consultaitonsReducer=(state=initailState,action)=>{
   switch(action.type){
       case GET_CONSULTATIONS:
          return {...state,isLoading:false,errMess:null,consultaitons:action.payload,successMess:null}
       case CLOSE_CONSULTATIONS:
          return {...state,isLoading:false,errMess:null,successMess:action.payload,consultaitons:[]}
       case ON_ERROR:
          return {...state,isLoading:false,errMess:action.payload,successMess:null,consultaitons:[]}
       case ON_SUCCESS:
          return {...state,isLoading:false,errMess:null,successMess:action.payload,consultaitons:[]}
       default:
          return state
   }

}