export const items = JSON.parse(localStorage.getItem('details'))
 const userDetails=items!==null?items:[]

export const token = userDetails.userToken

export const userType = userDetails.userType

export const userId = userDetails.userId

export const docId = userDetails.docId

export const username = userDetails.username

export const fullname = userDetails.fullname

export const picture = userDetails.picture

export const address = userDetails.address

export const role = userDetails.role

export const docFVerified = userDetails.Fverified

export const docCVerified = userDetails.Cverified

export const IwellRedirect = () => {
    if (token === '' || token === null || username === '' || userType === '' || userType === undefined) {
        window.location.href = '/login'
    }
}

