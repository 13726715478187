import React, { useEffect, useState } from 'react'
import Footer from '../containers/TheFooter'
import axios from 'axios'
import { baseurl } from '../baseUrl'
import { ErrorNotification, SuccessNotification } from './specials/notifications'
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import Loading from './specials/Loading'
import Swal from 'sweetalert2'


const ActivateAcc = () => {
    useEffect(() => {
        ActivateUserAccount()
    }, [])
    const [isError, setError] = useState(false)
    const [ErrorMessage, setErrorMessage] = useState('')
    const [isSuccess, setSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [isLoading, setLoading] = useState(false)

    var token
    const url = window.location.href
    var users = 'activate/users'
    var doctors = 'activate/doctors'
    async function ActivateUserAccount() {
        setLoading(true)
        if (url.includes(users)) {
            token = url.substring(url.indexOf('ers/') + 4, url.length)
            await axios.get(baseurl + 'users/authentication/activate/' + token).then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    setSuccess(true)
                    setSuccessMessage(res.data.message)
                    SuccessNotification(res.data.type, res.data.message)
                }
            }).catch(err => {
                if (err.response === undefined) {
                    setLoading(false)
                    setError(true)
                    ErrorNotification('iWello', err.message)
                    return setErrorMessage('Unable to connect to the internet. Kindly check your connectivity.')
                }
                setLoading(false)
                setError(true)
                setErrorMessage(err.response.data.message)
                ErrorNotification(err.response.data.type, err.response.data.message)
            })
        }
        if (url.includes(doctors)) {
            token = url.substring(url.indexOf('ors/') + 4, url.length)
            await axios.get(baseurl + 'doctors/account/activate/' + token).then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    setSuccess(true)
                    setSuccessMessage(res.data.message)
                    SuccessNotification(res.data.type, res.data.message)
                }
            }).catch(err => {
                if (err.response === undefined) {
                    setLoading(false)
                    setError(true)
                    ErrorNotification('iWello', err.message)
                    return setErrorMessage('Unable to connect to the internet. Kindly check your connectivity.')
                }
                console.log(err)
                setLoading(false)
                setError(true)
                setErrorMessage(err.response.data.error.message)
                ErrorNotification('Error', err.response.data.error.message)

            })
        }
    }

    const [sending, setSending] = useState(false)
    async function ResendActivation(e) {
        e.preventDefault()
        setSending(true)
        if (url.includes(users)) {
            token = url.substring(url.indexOf('ers/') + 4, url.length)
            await axios.post(baseurl + 'users/resendActivation/' + token).then(res => {
                if (res.status === 200) {
                    setSending(false)
                    SuccessNotification('iWello', res.data.message)
                    Swal.fire({
                        title: res.data.type,
                        text: res.data.message,
                        icon: 'success'
                    })
                }

            }).catch(err => {
                console.log(err.response,err)
                if (err.response === undefined) {
                    setSending(false)
                    ErrorNotification('iWello', err.message)
                    return alert(err.message)
                }
                setSending(false)
                ErrorNotification('Error', err.response.data.error.message)
                Swal.fire({
                    title: 'Error',
                    text: err.response.data.error.message,
                    icon: 'error'
                })
            })
        }
        if (url.includes(doctors)) {
            token = url.substring(url.indexOf('ors/') + 4, url.length)
            await axios.post(baseurl + 'doctors/resendActivationLink/' + token).then(res => {
                if (res.status === 200) {
                    setSending(false)
                    SuccessNotification('iWello', res.data.message)
                    Swal.fire({
                        title: res.data.type,
                        text: res.data.message,
                        icon: 'success'
                    })
                }

            }).catch(err => {
                if (err.response === undefined) {
                    setSending(false)
                    ErrorNotification('iWello', err.message)
                    return alert('Unable to connect to the internet. Kindly check your connectivity.')
                }
                console.log(err)
                setSending(false)
                ErrorNotification('Error', err.response.data.error.message)
                Swal.fire({
                    title: 'Error',
                    text: err.response.data.error.message,
                    icon: 'error'
                })
            })
        }

    }
    return (
        <div className="">
            <div className="row" style={{ height: '100vh' }}>

                <div class="col-12 col-md-6" style={{ backgroundColor: '#eeedfe' }}>
                    <img src="/avatars/iwell.PNG" alt="iwello logo" style={{
                        height: '57%',
                        width: '60%', marginTop: '20%', marginLeft: '20%'
                    }} />


                </div>
                <div class="col-12 col-md-6" style={{ backgroundColor: '#ffffff' }}>

                    {
                        isLoading ?
                            <p style={{ marginTop: '35%', marginLeft: '30%' }}>
                                <Spinner as="span" animation="grow" size="lg" role="status" aria-hidden="true"
                                /> Authenticating...please wait </p> :
                            isSuccess ?
                                <div className="alert alert-success fade show" style={{
                                    width: '80%',
                                    marginTop: '25%', marginLeft: '10%'
                                }}>
                                    <i className="fa fa-check-circle fa-4x" style={{ marginLeft: '40%' }}></i>
                                    <h5>{successMessage}</h5>
                                    <a href='/login'>
                                        <button className="btn btn-outline-primary">Sign in</button>
                                    </a>
                                </div> :
                                <div className="alert alert-danger fade show" style={{
                                    width: '80%',
                                    marginTop: '25%', marginLeft: '10%'
                                }}>
                                    <i className="fa fa-times-circle fa-4x" style={{ marginLeft: '40%' }}></i>
                                    <h5>{ErrorMessage}</h5>
                                    {
                                        sending ? <Loading title="Sending Activation link" /> :
                                            <div>
                                                <button className="btn btn-outline-info" onClick={ResendActivation}>Resend Activation</button>

                                                <a href='/login' style={{ marginLeft: '55%' }}>
                                                    <button className="btn btn-outline-primary">Sign in</button>
                                                </a>
                                            </div>

                                    }
                                </div>
                    }


                </div>

            </div>
            <Footer />
        </div>
    )
}

export default ActivateAcc