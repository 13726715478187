import React from 'react';

const Loading = (props) => {
    return (
        <div>
            <div className="col-12" style={{ marginLeft: '34%' }}>
                <span className="fa fa-spinner fa-pulse fa-3x fa-fw text-primary"></span>
                <p>{props.title} . . .</p>
            </div>
        </div>

    );
};


export default Loading