import React, { useState } from 'react'
import Footer from '../containers/TheFooter'
import axios from 'axios'
import { baseurl } from '../baseUrl'
import { ErrorNotification, SuccessNotification, WarningNotification } from './specials/notifications'
import { Spinner, Form } from 'react-bootstrap';
import Swal from 'sweetalert2'
import {
    CButton,
    CCard,
    CCardBody,
    CCol,
    CRow,
} from '@coreui/react'


const ResetPassword = () => {

    const [isError, setError] = useState(false)
    const [ErrorMessage, setErrorMessage] = useState('')
    const [isSuccess, setSuccess] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const [confpassword, setConfPassword] = useState('')

    async function ResetPass(e) {
        e.preventDefault()
        setLoading(true)
        setErrorMessage('')
        var token
        const url = window.location.href
        var users = 'resetpassword/users/'
        var doctors = 'resetpassword/doctors/'
        
        if (password !== confpassword) {
            setError(true)
            setLoading(false)
            WarningNotification('iWello', 'The two passwords do not match')
            return setErrorMessage('Make sure the two passwords match')
        }
        if (url.includes(users)) {
            token = url.substring(url.indexOf('ers/') + 4, url.length)
            await axios.post(baseurl + 'users/resetPassword', {
               token: token,newpassword:password
            }).then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    setSuccess(true)
                    setSuccessMessage(res.data.message)
                    SuccessNotification(res.data.type, res.data.message)

                    Swal.fire({
                        title: res.data.type,
                        text: res.data.message,
                        icon: 'success',
                        showConfirmButton: true,
                        confirmButtonText: "Proceed to login"
                    }).then(results => {
                        if (results.isConfirmed) {
                            window.location.href = '/login'
                        }
                    })

                }
            }).catch(err => {
                console.log(err.response)
                if (err.response === undefined) {
                    setLoading(false)
                    setError(true)
                    ErrorNotification('iWello', err.message)
                    return setErrorMessage('Unable to connect to the internet. Kindly check your connectivity.')
                }
                setLoading(false)
                setError(true)
                setErrorMessage(err.response.data.message)
                ErrorNotification('iWello', err.response.data.message)
                Swal.fire({
                    title: 'Error',
                    text: err.response.data.message,
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonText: "Ok"
                })
            })
        }
        if (url.includes(doctors)) {
            token = url.substring(url.indexOf('ors/') + 4, url.length)

            await axios.post(baseurl + 'doctors/reset-password',{
                token:token,
                newpassword:password
            }).then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    setSuccess(true)
                    setSuccessMessage(res.data.message)
                    SuccessNotification(res.data.type, res.data.message)
                    Swal.fire({
                        title: res.data.type,
                        text: res.data.message,
                        icon: 'success',
                        showConfirmButton: true,
                        confirmButtonText: "Proceed to login"
                    }).then(results => {
                        if (results.isConfirmed) {
                            window.location.href = '/login'
                        }
                    })
                }
            }).catch(err => {
                if (err.response === undefined) {
                    setLoading(false)
                    setError(true)
                    ErrorNotification('iWello', err.message)
                    return setErrorMessage('Unable to connect to the internet. Kindly check your connectivity.')
                }
                console.log(err.response)
                setLoading(false)
                setError(true)
                setErrorMessage(err.response.data.message)
                ErrorNotification('iWello', err.response.data.error.message)
                Swal.fire({
                    title: 'Error',
                    text: err.response.data.error.message,
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonText: "Ok"
                })

            })
        }
    }
    return (
        <CCard>
            <CCardBody>
            <CRow style={{ height: '100vh' }}>

                <div class="col-12 col-sm-6 col-md-6" style={{ backgroundColor: '#eeedfe' }}>
                    <img src="/avatars/iwell.PNG" alt="iwello logo" style={{
                        height: '50%',
                        width: '50%', marginTop: '20%', marginLeft: '20%'
                    }} />


                </div>
                <div class="col-12 col-sm-6 col-md-6" style={{ backgroundColor: '#ffffff' }}>


                    <button className="btn btn-ghost-primary btn-block" type="button"
                        style={{ width: "83px", marginLeft: '68%', marginTop: '3%' }}
                        onClick={() => window.location.href = "/login"}>
                        Sign In</button>



                    <CCol sm='6'  md='6' className="offset-sm-3">
                    <h5 style={{ marginLeft: '18%', marginTop: '20%' }}>Reset Your Password here</h5>

                    <Form onSubmit={ResetPass} style={{ marginTop: '10%' }}>
                        {
                            isError ?
                                <p style={{ color: 'red' }}>{ErrorMessage}</p>
                                : ''
                        }

                        <Form.Group className="form-group">
                            <Form.Control type="password" className="form-control" placeholder="new password"
                                value={password} onChange={e => setPassword(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className="form-group">
                            <Form.Control type="password" className="form-control" placeholder="Confirm password"
                                value={confpassword} onChange={e => setConfPassword(e.target.value)} required />
                        </Form.Group>
                        {
                            isLoading ?
                                <button className="btn btn-primary btn-block btn-pill active"
                                    aria-pressed="true" style={{ width: '80%', marginLeft: '10%', marginTop: '5%' }} disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="lg"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                        Saving Changes...
                                        </button> :
                                <button className="btn btn-primary btn-block btn-pill active" type="submit" aria-pressed="true"
                                    style={{ width: '80%', marginLeft: '10%', marginTop: '5%' }}>
                                    Save changes</button>

                        }
                    </Form>
                    </CCol>
                   
                </div>

            </CRow>
            </CCardBody>
            <Footer />
        </CCard>
    )
}

export default ResetPassword