import { store } from 'react-notifications-component';
import {  toast } from 'react-toastify';


export const SuccessNotification = (title, message) => {

    return (
        store.addNotification({
            title: title,
            message: message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 8000,
                onScreen: true
            }

        })
    )

}

export const InfoNotification = (title, message) => {

    return (
        store.addNotification({
            title: title,
            message: message,
            type: 'info',
            insert: 'top',
            container: 'top-right',
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 8000,
                onScreen: true
            }

        })
    )

}

export const WarningNotification = (title, message) => {

    return (
        store.addNotification({
            title: title,
            message: message,
            type: 'warning',
            insert: 'top',
            container: 'top-right',
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 8000,
                onScreen: true
            }

        })
    )

}
export const ErrorNotification = (title, message) => {

    return (
        store.addNotification({
            title: title,
            message: message,
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 8000,
                onScreen: true
            }

        })
    )

}

export const ToastInfo=(message,positon)=>{
    return toast.info(message, {
                    position: positon?positon:'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
              });
}

export const ToastWarning=(message,positon)=>{
    return toast.warning(message, {
                    position: positon?positon:'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
              });
}

export const ToastError=(message,positon)=>{
    return toast.error(message, {
                    position: positon?positon:'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
              });
}

export const ToastSuccess=(message,positon)=>{
    return toast.success(message, {
                    position: positon?positon:'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
              });
}