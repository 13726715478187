import React from 'react'
import { CFooter } from '@coreui/react'

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <footer className="row col-12 col-md-12" style={{
        bottom: '0px', position: 'fixed', backgroundColor: '#f7f7f7', width: '100vw',
        height: '38px', paddingTop: '10px', paddingLeft: '6%'
      }}>

        <div className="col-sm-3">
         <a href="http://www.facebook.com/iwelloNG"> <i className="fa fa-facebook-f"></i></a>
         <a href="http://www.twitter.com/iwelloNG"><i className="fa fa-twitter" style={{ marginLeft: '20px' }}></i></a>
         <a href="http://www.instagram.com/iwelloNG"><i className="fa fa-instagram" style={{ marginLeft: '20px' }}></i></a>
         <a href="http://www.linkedin.com/company/iwellong"><i className="fa fa-linkedin" style={{ marginLeft: '20px' }}></i></a>
        </div>

        <p style={{ marginLeft: '40%' }}>&#169; 2022 <a href="#" style={{ color: "#3532eb" }}>iWello</a></p>


      </footer>
    </CFooter>
  )
}

export default React.memo(TheFooter)
