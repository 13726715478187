import {GET_DOCTORS,LOAD_DOCTORS,DOCTORS_FAILED} from '../constants/actionTypes'

const initialState={
    doctors:[],
    isLoading:true,
    errMess:null,    
}

export const doctorReducer=(state= initialState,action)=>{
   switch (action.type) {
       case GET_DOCTORS:
           return {...state,doctors:action.payload,isLoading:false,errMess:null};   
        case LOAD_DOCTORS:
           return {...state,doctors:[],isLoading:true,errMess:null};
        case DOCTORS_FAILED:
           return {...state,doctors:[],isLoading:false,errMess:action.payload}
       default:
           return state;
   }
}