import {GET_DATA,LOAD_DATA,FAILED_DATA} from  '../constants/dashboardActionTypes'

const initialState={
    isLoading:true,
    data:[],
    errMess:null,
    successMess:null
}

export const dashboardReducer=(state=initialState,action)=>{
   switch(action.type){
       case GET_DATA:
         return {...state,isLoading:false,errMess:null,successMess:null,data:action.payload}
       case LOAD_DATA:
         return {...state,isLoading:true,errMess:null,successMess:null,data:[]}
       case FAILED_DATA:
         return {...state,isLoading:false,errMess:action.payload,successMess:null,data:[]}
       default:
         return state;
   }
}