import React,{createContext,useState,useMemo} from 'react';
import  {items} from './authHeaders'

export const UserContext = createContext();

export const UserProvider=(props)=>{
 const [User,setUser] = useState(items);
 const value = useMemo(
    () => ({ User, setUser }), 
    [User]
  );

return(
    <UserContext.Provider value={value}>
        {props.children}
    </UserContext.Provider>

)
}