
export const  ADD_DOCTOR='ADD_DOCTOR'
export const  GET_DOCTORS='GET_DOCTORS'
export const  LOAD_DOCTORS='LOAD_DOCTORS'
export const  DOCTORS_FAILED='DOCTORS_FAILED'
export const  GET_CONSULTATIONS='GET_CONSULTATIONS'
export const  CLOSE_CONSULTATIONS='CLOSE_CONSULTATIONS'
export const  START_CONSULTATION='START_CONSULTATION'
export const  ON_ERROR='ON_ERROR'
export const ON_SUCCESS="ON_SUCCESS"
export const ON_LOAD="ON_LOAD"