import {GET_USERS,LOAD_USERS,FAILED_USERS} from '../constants/usersActionTypes'

const initailState={
    users:[],
    isLoading:true,
    errMess:null,
}

export const usersReducer=(state=initailState,action)=>{
    switch (action.type){
        case GET_USERS:
          return{isLoading:false,errMess:null,users:action.payload}
        case LOAD_USERS:
          return{isLoading:true,errMess:null,users:[]}
        case FAILED_USERS:
          return {isLoading:false,errMess:action.payload,users:[]}
        default:
          return state
    }
}