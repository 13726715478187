import { createStore,combineReducers ,applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {doctorReducer,consultaitonsReducer,profileReducer,usersReducer,stfReducer,dashboardReducer} from '../src/redux/reducers/index'

const initialState = {
  sidebarShow: 'responsive'
}

 const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const Reducers= combineReducers({  
  changeState:changeState,
  Doctors:doctorReducer,
  consultaitons:consultaitonsReducer,
  profile:profileReducer,
  users:usersReducer,
  STF:stfReducer,
  dashboard:dashboardReducer
})

const RedThunk= applyMiddleware(thunk)
const devToools= window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

const store = createStore(
 Reducers, 
 RedThunk
 )
export default store