import React, { Component } from 'react';
import { BrowserRouter, Route, Switch,Redirect } from 'react-router-dom';
import './scss/style.scss';
import AccActivation from './iWellComponents/accountActivation'
import ResetPassword from './iWellComponents/resetpassword'
import { clientUrl } from './baseUrl'

const loading = (
  <>
  
 <div class="loading">
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
  <div class="dot"></div>
 
</div>
  
</>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const GetStarted = React.lazy(() => import('./iWellComponents/getStarted'))
const IwellLogin = React.lazy(() => import('./iWellComponents/login'))
const SignUp = React.lazy(() => import('./iWellComponents/signUp'))


class App extends Component {

  componentDidMount() {
    if(window.location.href===clientUrl){
      return  <Redirect from='/' to='/home'/>
    }
  }
  render() {
    
    if (window.location.href.includes(clientUrl + 'account/activate/')) {

      return <AccActivation />
    }
    if (window.location.href.includes(clientUrl + 'resetpassword/')) {

      return <ResetPassword />
    }
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            
            {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} /> */}
            {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} /> */}
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route exact path="/home" name="Get Started" render={props => <GetStarted {...props} />} />
            <Route exact path="/login" name="Iwello login" render={props => <IwellLogin {...props} />} />
            <Route exact path="/signup" name="Iwello sign up" render={props => <SignUp {...props} />} />         
           
            <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
           
           

          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
