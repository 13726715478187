import {GET_PROFILE,LOAD_PROFILE,FAILED_PROFILE} from '../constants/profileActionTypes'

const initailState={
    profile:[],
    isLoading:true,
    errMess:null,
}

export const profileReducer=(state=initailState,action)=>{
    switch (action.type){
        case GET_PROFILE:
          return{isLoading:false,errMess:null,profile:action.payload}
        case LOAD_PROFILE:
          return{isLoading:true,errMess:null,profile:[]}
        case FAILED_PROFILE:
          return {isLoading:false,errMess:action.payload,profile:[]}
        default:
          return state
    }
}