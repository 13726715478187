// export const baseurl = 'https://api.iwello.org/'

export const clientUrl = "https://iwello.org/";
export const baseurl= "https://api-iwello.onrender.com/";

// export const clientUrl = 'https://iwello.herokuapp.com'

// export const clientUrl = 'http://localhost:3000/'

// export const baseurl = 'http://localhost:4000/'
