import {GET_STF,LOAD_STF,FAILED_STF} from '../constants/stfActonTypes'

const initialState={
    STFs:[],
    isLoading:true,
    errMess:null
}

export const stfReducer=(state=initialState,action)=>{
    switch(action.type){
        case GET_STF:
          return {isLoading:false,errMess:null,STFs:action.payload}
        case LOAD_STF:
          return {isLoading:true,errMess:null,STFs:action.payload}
        case FAILED_STF:
          return {isLoading:false,errMess:action.payload,STFs:[]}
        default:
          return state
    }
}